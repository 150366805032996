<p-menubar [model]="items">
  <div>
    <img style="vertical-align: bottom" src="{{claims['picture']}}" alt="{{claims['given_name']}}" width="36px" height="36px">
  </div>
</p-menubar>
<!--
<p></p>
<p>&#45;&#45;&#45;&#45;</p>
<nav>
  <ul>
    <li><a routerLink="/dashboard" routerLinkActive="active">Dashboard</a></li>
    <li><a routerLink="/menuitem/2" routerLinkActive="active">Menu Item</a></li>
  </ul>
</nav>
-->
