<div *ngIf="claims; else loginTemplate">
<!--
  <h4>User Info</h4>
  <p>Hello {{claims['given_name']}}. This are your claims...</p>
  <pre>{{ claims | json }}</pre>

  <button type="button" sbbButton mode="secondary" (click)="logout()">Logout</button>

  <h3>Test calling API using ID token...</h3>
  <p></p>
  <button type="button" sbbButton (click)="callApi()">Get Recipes</button>

  <div class="container">
    <table class="table table-striped table-bordered">
       <tbody>
      <tr *ngFor="let item of apiResult">
        <td><pre>{{ item | json }}</pre></td>
      </tr>
      </tbody>
    </table>
  </div>
-->
  <app-nav></app-nav>
<!--
  <button type="button" sbbButton mode="secondary" (click)="logout()">Logout</button>
-->
</div>

<ng-template #loginTemplate>
  <h1>{{ title }}</h1>
<!--
  <p>Claims:</p>
  <pre>{{ claims | json }}</pre>
  <button type="button" sbbButton mode="secondary" (click)="logout()">Logout</button>
-->
  <button type="button" sbbButton (click)="login()">Se connecter avec Google...</button>
</ng-template>

<router-outlet></router-outlet>
